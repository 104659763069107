.agoda body {
	background-color: rgb(243, 243, 243);
}


/* styles as defined by className properties need to be marked !important */
.agoda .appbar {
	background: #FFFFFF !important;
	border-bottom: 3px solid #14a9e0;
}

.agoda .drawer {
	z-index: 0 !important;
	width: 0 !important;
}

.button-action {
	min-width: unset !important;
	min-height: unset !important;
	height:20px;
	padding:0;
	margin:0;
}

.button-action > span {
	font-size: 60%;
	padding:0;
	margin:0;
	position: absolute;
}

.agoda .container {
	background-color: rgb(243, 243, 243);
}

.agoda .class-root {
  z-index: 1201;
  overflow: hidden;
  position: relative;
  display: flex;
}
.agoda .class-content {
  z-index: 0;
  height: 430px;
  position: relative;
  padding: 20px;
  height: 100%;
  translate: transformY(-15%);
  min-width: 0px;
  max-width: 100%;
}

.agoda .card-status-indicator {
	display:inline-block;
	text-align:center;
	vertical-align: middle;
	width:20px;
	height:20px;
	line-height:20px;
	margin-left: 5px;
	cursor: help;
	border-radius: 50%;
	background-color: rgba(244, 67, 54, 0.15);
}

.agoda .batch-load-form-container,
.agoda .batch-devalue-form-container,
.agoda .insert-card-fee-form-container,
.agoda .card-search-form-container {
	display: flex;
	margin-bottom: 10px;
}

.agoda .batch-load-form-container .form-input,
.agoda .batch-devalue-form-container .form-input,
.agoda .insert-card-fee-form-container .form-input,
.agoda .card-search-form-container .form-input {
	width:50%;
}

.agoda .batch-load-form-container .form-input,
.agoda .batch-devalue-form-container .form-input,
.agoda .insert-card-fee-form-container .form-input,
.agoda .card-search-form-container .form-input {
	margin-left:10px;
	margin-right:10px;
}

.agoda .batch-load-form-container .form-input:first-child,
.agoda .batch-devalue-form-container .form-input:first-child,
.agoda .insert-card-fee-form-container .form-input:first-child,
.agoda .card-search-form-container .form-input:first-child {
	margin-left:0px;
	margin-right:10px;
}

.agoda .batch-load-form-container .form-input:last-child,
.agoda .batch-devalue-form-container .form-input:last-child,
.agoda .insert-card-fee-form-container .form-input:last-child ,
.agoda .card-search-form-container .form-input:last-child {
	margin-left:10px;
	margin-right:0px;
}

.agoda .batch-form-switch {
	padding-top:10px;
	width:auto !important;
}

.agoda .batch-form-switch .form-input-label {
	white-space: nowrap;
}

.popover-paper .popover-close {
	float:right;
	margin-right:10px;
	margin-top:5px;
	cursor:pointer;
}

.batch-file-header span.batch-file-name {
	color:rgb(12,179,157);
}

.batch-file-header .batch-file-header-chip {
	font-style: italic;
	color:rgba(34,40,102,0.7);
}

.batch-loads-filter {
	float:left;
	margin-top:5px;
}

.batch-loads-results-refresh {
	margin-left:20px;
}

.approve-batch-loads-table tr th,
.approve-batch-loads-table tr td,
.batch-file-table tr th,
.batch-file-table tr td  {
	padding: 0px 0px 0px 0px !important;
	margin:0px !important;
}

.agoda .batch-load-details-close {
	margin-left: 50px;
}

.agoda .batch-loads-date-filter {
	float:right;
	margin-top:5px;
}

.agoda .batch-loads-date-filter .batch-loads-date-filter-separator {
	display:inline-block;
	padding:5px 20px;
}

.card-header {
	color: rgb(255,255,255) !important; /* accent1Color */
	background-color: rgb(12,179,157) !important; /* primary1Color */
	margin-bottom: 20px !important;
}

.card-header-text{
	color:white;
}

.card-home {
	width: 700px;
}

.card-home-wrapper{
	display: flex;
	width: 100%;
	justify-content: center;
	padding-bottom: 111px;
}

.card-home-card { 
	padding: 40px;
	background-color: rgb(255, 255, 255) !important;
}

.cardmedia-home {
	height: 0;
	padding-top: 30% !important;
}

.agoda .flattbutton-login:active {
	color: rgb(255,255,255) !important; /* accent1Color */
	background-color: rgb(12, 179, 157) !important; /* primary1Color */
}
.agoda .flattbutton-login:hover {
	color: rgb(255,255,255) !important; /* accent1Color */
	background-color: rgb(34, 40, 102) !important; /* primary2Color */
}

.agoda .flattbutton-search {
	margin-top: 10px;
	float: right;
	color: rgb(255, 255, 255) !important; /* accent1Color */
	background-color: rgb(20,169,224) !important; /* primary1Color */
}
.agoda .flattbutton-search:hover {
	color: rgb(255, 255, 255) !important; /* accent1Color */
	background-color: rgb(34, 40, 102) !important; /* primary2Color */
}

.agoda .table-icon-link {
	color: rgb(34, 40, 102) !important; /* primary2Color */
}

.agoda .align-right {
	display: block;
	margin-left: auto;
	width: 100%;
	text-align:right;
}

.agoda .div-padding {
	padding: 2px;
	font-size: 0.9em;
}
	
.agoda .cardOrderTable {
	color: rgb(34,40,102) !important; /* primary2Color */;
}
.agoda .processed {
	color: rgb(31,195,52);
}

.agoda .cardOrderTable {
	color: rgb(34,40,102) !important; /* primary2Color */;
}
.agoda .processed {
	color: rgb(31,195,52);
}

.agoda .menuitem-hover {
	background-color: rgba(145, 145, 145, 0.8);
}
.agoda .menutext{
	font-size: 13px !important;
}
.agoda .menuitem-active {
	border-right: 5px solid rgb(34, 40, 102);
}
.menuitem-pad-1 {
  padding-left: 3em !important;
}
.menuitem-pad-2 {
  padding-left: 4em !important;
}
.agoda .menuitem-heading {  
  width: 100% !important;
  font-weight: 600 !important;
  color: #606161;
}
.agoda .menuitem-heading-active {  
  font-weight: 400 !important;
}
.agoda .menuitem-heading:hover {
  background-color: rgb(255, 255, 255);
}

.agoda .menuitem {
	color: #606161;
}

.carddesign {
	display: flex;
	flex-flow: row wrap;
	align-items: center; /* vertically center items (for vert card designs) */
	align-content: stretch; /* default - rows have same height */
	/* overflow: hidden; */
}
.carddesign-gridList {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	cursor: pointer;
}
.carddesign-tile {
	width: 334px;
	height: 211px;
}
.carddesign-tile:hover {
	background: linear-gradient(to bottom, rgba(34,40,102,0.8), rgba(34,40,102,0)) !important;
}
.agoda .carddesign-title {
	color: rgb(255,255,255) !important; /* accent1Color */
}
.carddesign-titlebar {
	background: linear-gradient(to top, rgba(34,40,102,0.8), rgba(34,40,102,0)) !important;
}

.homeImage {
    width: 100%;
    height: 100%;
}

.cardImage {
	display: block;
	margin-left: auto;
	margin-right: auto;
	height: 211px;
	padding: 15px;
}

.align-right {
	display: block;
	margin-left: auto;
	width: 156px;
}

.select {
	width: 100%;
}

.p-error {
	color: rgb(244,67,54) !important; /* red500 */
}

.dropzone-image {
	display: block;
	padding-top: 40px;
	margin-left: auto;
	margin-right: auto;
}
.dropzone {
	border-style: dashed;
	border-width: 2px;
	border-color: rgb(158, 158, 158) !important; 
	background: linear-gradient(to right bottom, rgba(12,179,157,0.05), rgba(34,40,102,0.05)) !important;
}
.dropzone:hover {
	background: linear-gradient(to right bottom, rgba(12,179,157,0.1), rgba(34,40,102,0.1)) !important;
}
.dropzone-p {
	padding: 40px;
	text-align: center;
}

.header {
	position: relative;
}

.table-header {
	padding:20px;
}

.reportcard {
	margin-bottom: 10px;
	cursor: pointer;
}

.reportcard:hover {
	background: rgb(20,169,224, 0.8) !important;
}

.reportcard:hover .reportcardheadertext {
	color: #FFFFFF !important;
}

.reportcard:hover .reportcardicon {
	color: #FFFFFF !important;
}

.card-search-results .customer-reference,
.card-search-results .tracking-number {
	word-wrap: anywhere;
}
