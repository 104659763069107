body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.header {
  position: relative;
  height: 64px;
  width: calc(100% + 240px);
}

.appbar {
	height:64px;
	display:flex;
	flex-direction: column;
	flex-wrap: nowrap;
	justify-content: center;
	z-index: 1200;
}

.drawer {
	z-index: 1 !important;
	width: 0 !important;
}

.class-root {
  z-index: 1201;
  overflow: hidden;
  position: relative;
  display: flex;
}
.class-content {
  height: 430px;
  position: relative;
  padding: 20px;
  height: 100%;
  translate: transformY(-15%);
  min-width: 0px;
  max-width: 100%;
}
.class-content-spaced {
  min-height: 863px;
  position: relative;
  min-width: 0px;
  left: 230px;
  margin-top: 64px;
  width: calc(100% - 210px); /* menuitem width, padding */
}
